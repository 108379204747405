:root {
    --font-family: "Almarai";
    --side-navbar-collapsed-width: 3rem;
    /* --color-dark-0: #032530; */
    /* --color-dark-1: #032530; */
    --color-bassthalk: #11baf0;
    --color-azure: #e7f8fd;

    /* --color-primary-container: var(--color-azure); */
    /* --color-inner-container: var(--color-azure); */

    --font-family: "Almarai", sans-serif;

    /* --body-top: 0px; */
}
html.darkmode,
body.darkmode {
    /* --color-text-primary: var(--color-azure); */
}
.planner-text {
    font-size: 18px;
}
.clr-white {
    color: var(--color-white);
}
.bg-white {
    background-color: var(--color-azure);
}

.hover\:clr-white:hover {
    color: var(--color-azure);
}
.hover\:bg-white:hover {
    background-color: var(--color-azure);
}

@font-face {
    font-family: "FS Albert Arabic";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/fs-albert.ttf") format("truetype");
}
@font-face {
    font-family: "balabeloo";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/Blabeloo-font-v.1.0.ttf") format("truetype");
}

@font-face {
    font-family: "FS Albert Arabic";
    font-weight: bold;
    font-style: normal;
    src: url("./assets/fonts/fs-albert-700.ttf") format("truetype");
}
@font-face {
    font-family: "Somar";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/ArbFONTS-Somar-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Somar";
    font-weight: 600;
    font-style: normal;
    src: url("./assets/fonts/ArbFONTS-Somar-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Somar";
    font-weight: 700;
    font-style: normal;
    src: url("./assets/fonts/ArbFONTS-Somar-Bold.otf") format("opentype");
}
@font-face {
    font-family: "NeueMetana";
    font-weight: 900;
    font-style: normal;
    src: url("./assets/fonts/NeueMetana-Bold.otf") format("opentype");
}
@font-face {
    font-family: "NeueMetana";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/NeueMetana-Regular.otf") format("opentype");
}
@font-face {
    font-family: "The Sans Alinma";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/ArbFONTS-The-Sans-Plain-alinma.ttf") format("truetype");
}
@font-face {
    font-family: "The Sans Alinma";
    font-weight: 700;
    font-style: normal;
    src: url("./assets/fonts/ArbFONTS-TheSans-Bold-alinma.ttf") format("truetype");
}
@font-face {
    font-family: "ExpoArabic";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/Expo\ Arabic\ Book.ttf") format("opentype");
}
@font-face {
    font-family: "ExpoArabic";
    font-weight: 600;
    font-style: normal;
    src: url("./assets/fonts/Expo\ Arabic\ Medium.ttf") format("opentype");
}
@font-face {
    font-family: "ExpoArabic";
    font-weight: 700;
    font-style: normal;
    src: url("./assets/fonts/Expo\ Arabic\ Bold.ttf") format("opentype");
}
@font-face {
    font-family: "Caveat";
    font-weight: 400;
    font-style: normal;
    src: url("./assets/fonts/Caveat-VariableFont_wght.ttf") format("opentype");
}

@font-face {
    font-family: "DG-Forsha";
    font-weight: 400;
    font-style: normal;
    src: url("./assets/fonts/DG\ Forsha\ Regular.ttf") format("truetype");
}

@font-face {
    font-family: "DG-Forsha-Scribble";
    font-weight: 400;
    font-style: normal;
    src: url("./assets/fonts/DG\ Forsha\ Scribble.ttf") format("truetype");
}

.font-expo {
    font-family: "ExpoArabic", sans-serif;
}

.font-forsha {
    font-family: "DG-Forsha", sans-serif;
}
.font-Scribble {
    font-family: "DG-Forsha-Scribble", sans-serif;
}

.font-caveat {
    font-family: "Caveat", sans-serif;
}

.font-som {
    font-family: "Somar", sans-serif;
}
/* body {
    height: 1000vh;
    direction: ltr;
} */
.pisitive-nav-top {
    top: calc(var(--navbar-height));
}

.font-lem {
    font-family: "Lemonada", sans-serif;
}
.font-neu {
    font-family: "NeueMetana", sans-serif;
}
.font-the-sans {
    font-family: "The Sans Alinma", sans-serif;
}

.font-balabeloo {
    font-family: "balabeloo", sans-serif;
}

.card-store-shadow {
    box-shadow: 0 1px 0 rgba(17, 17, 26, 0.1), 0 8px 24px rgba(17, 17, 26, 0.1);
}

.table-study-style {
    border-radius: 12px;
    border-collapse: separate;
    border-spacing: 0;
}

.table-study-style td,
.table-study-style th {
    border: 1px solid var(--color-text-secondary);
    transition: all var(--transition-smooth);
    padding: 8px;
}

.fade-down-in {
    animation: fadeDownIn 0.5s ease-in-out;
    opacity: 1;
}

.fade-up-out {
    animation: fadeUpOut 0.5s ease-in-out;
    opacity: 0;
}

@keyframes fadeDownIn {
    0% {
        opacity: 0;
        transform: translateY(-10%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeUpOut {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-10%);
    }
}

@supports (-webkit-text-stroke: 0.5px theme("colors.violet.500")) {
    .text-stroke {
        -webkit-text-stroke: 0.5px theme("colors.violet.500");
        transition: all 0.4s ease;
    }
    .text-stroke-none {
        color: inherit;
        -webkit-text-stroke-width: 0;
    }

    .text-stroke.fade-in-out {
        transition: all 1s ease-in-out;
    }
}
