:root {
    --wheel-size: 400px;
    --pointer-size: 20px;
    --slice-color-even: #6c79e2;
    --slice-color-odd: #8693f4;
    --neutral-color: white;
    --divider-color: rgba(0, 0, 0, 0.4);
    --nb-item: 0;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 5;
    --spinning-duration: 4s;
    --reset-duration: 0.25s;
}

.wheel-wrapper {
    position: relative;
    width: var(--wheel-size);
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wheel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: var(--wheel-size);
    height: var(--wheel-size);
    border-radius: 50%;
    box-shadow: 0 10px 15px rgba(104, 121, 254, 0.3);
    overflow: hidden;
    cursor: pointer;
    background: radial-gradient(circle, #f1f4ff, #d8dffe);
}

.wheel {
    width: 100%;
    height: 100%;
    position: relative;
    transform: rotate(0deg);
    transition: transform var(--reset-duration);
    background: conic-gradient(
        var(--slice-color-even) 0%,
        var(--slice-color-even) 45%,
        var(--slice-color-odd) 50%,
        var(--slice-color-odd) 95%,
        var(--slice-color-even) 100%
    );
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.2);
    border: 2px dotted #3949ab;
    border-radius: 50%;
    overflow: hidden;
}

.wheel.spinning {
    transition: transform var(--spinning-duration) ease-out;
    transform: rotate(
        calc(var(--nb-turn) * 360deg + (-360deg * var(--selected-item) / var(--nb-item)))
    );
}

.wheel-item {
    display: block;
    position: absolute;
    box-sizing: border-box;

    top: 50%;
    left: 50%;
    width: 50%;
    transform-origin: center left;
    transform: translateY(-50%) rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));

    color: var(--neutral-color);
    text-align: right;
    padding: 0 25px 0 50px;
    font-size: 12px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}

.wheel-item::before,
.wheel-item::after {
    content: "";
    position: absolute;
    width: 80%;
    height: 2px;
    left: 10%;
    transform-origin: center;
    border-radius: 2px;
    background: linear-gradient(10deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
}

.wheel-item::before {
    top: -10px;
}

.wheel-item::after {
    bottom: -10px;
}
.pointer {
    position: absolute;
    top: 50%;
    right: 140px;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(180deg);
    z-index: 4;
    width: 0;
    height: 0;
    border-top: var(--pointer-size) solid transparent;
    border-bottom: var(--pointer-size) solid transparent;
    border-right: calc(var(--pointer-size) * 1.5) solid var(--neutral-color);
}

.wheel::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    background: white;
    border-radius: 50%;
    z-index: 3;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    :root {
        --wheel-size: 330px;
    }
    .wheel-item {
        font-size: 10px;
    }
    .pointer {
        right: 110px;
    }
    .swal2-popup {
        width: 97% !important;
    }
    .swal2-html-container {
        margin: 0 0 !important;
    }
}
