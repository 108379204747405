.whatsapp-audio-player {
    border-radius: 20px;
    padding: 10px 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
}

.whatsapp-audio-player .text-gray-600 {
    transition: color 0.2s ease;
}

.whatsapp-audio-player .bg-blue-500 {
    transition: width 0.1s ease;
}

.whatsapp-audio-player:hover .text-gray-600 {
    color: #1a73e8;
}

.whatsapp-audio-player .flex-grow {
    width: 100%;
}
